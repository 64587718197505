/*--------------------------------------------------------------
# Fonts
--------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* black and Goldenen theme */
:root {
  /* Main theme color */
  --portal-theme-primary: #f6bd4b;
  --portal-theme-primary-filter-color: invert(74%) sepia(96%) saturate(396%)
    hue-rotate(332deg) brightness(101%) contrast(93%);
  --portal-theme-secondary: rgba(175, 151, 102, 0.199);
  --text-primary-color: white;
  --text-secondary-color: rgb(150, 150, 150);
  /* Main background colors */
  --background-primary-color: black;
  --background-secondary-color: black;
  /* Sidebar Properties */
  --sidebars-text-color: white;
  --sidebars-active-color: #f6bd4b;
  --sidebars-hover-color: #f6bd4b;
  --sidebars-background-color: #1d1c1d;
  /* Popup / Popover properties  */
  --popup-background-color: black;
  --popup-text-color: white;
  /* Element properties */
  --input-text-color: #fff;
  --input-placeholder-color: #fff;
  --input-background-primary: #fff;
  --input-background-secondary: #fff;
  --icons-color-allover: #f6bd4b;
  /* Button Properties */
  --button-background-color: #1c1c1c;
  --button-text-color: #f6bd4b;
  --button-outlined-color: #f6bd4b;
  --button-hover-color: "#f6bd4b";
  --audio-color: "#1d1c1d";
  --calendar-selected-box: #8b8b8b;
  --datepicker-selected-color: #fff;
}
/*  in chrome this works */
/* ::-webkit-calendar-picker-indicator {
  filter: invert(1);
} */
/* HINA KHAN IN DARK MODE CONVERSION STARTS FROM HERE */
body {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  background-color: var(--background-primary-color) !important;
  color: var(--text-primary-color) !important;
}

.what-include {
  margin-top: 0px !important;
}
.secondary-background {
  background-color: var(--background-secondary-color);
}
.css-17mra34-MuiPaper-root-MuiCard-root {
  background-color: transparent !important;
  color: var(--text-primary-color);
}
.css-2tnm32.Mui-selected {
  background-color: var(--portal-theme-primary) !important;
}

::-webkit-media-controls-time-remaining-display,
audio::-webkit-media-controls-current-time-display {
  color: var(--portal-theme-primary);
}
::-webkit-media-controls-enclosure {
  background-color: var(--portal-theme-secondary);
}

.css-18dh5wg-MuiTypography-root {
  color: var(--text-primary-color) !important;
}
.css-6obpvq-MuiTypography-root-MuiLink-root {
  color: var(--text-primary-color);
}
/* calender colors */
.MuiCalendarPicker-root {
  background-color: var(--popup-background-color) !important;
  color: var(--input-text-color);
}
.MuiCalendarPicker-root button {
  background-color: transparent !important;
  color: var(--input-text-color);
}
.MuiCalendarPicker-root .Mui-selected {
  background-color: var(--portal-theme-primary) !important;
  color: var(--datepicker-selected-color);
}

input[type="time"]::-webkit-calendar-picker-indicator {
  filter: var(--portal-theme-primary-filter-color);
  cursor: pointer;
}

/* calender colors end */

/* card background */
/* .css-1dg1xmf-MuiPaper-root-MuiCard-root {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
} */
.css-1mav5hp-MuiPaper-root-MuiDrawer-paper {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
}
.css-ju84cc-MuiButtonBase-root-MuiMenuItem-root,
.css-1h0kuck-MuiButtonBase-root-MuiButton-root {
  color: var(--text-primary-color);
}
.css-1fuveia-MuiPaper-root-MuiPopover-paper,
.css-13pz97u {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
}
.css-1uc7r6i {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
}
/* .css-1bsqz8-MuiPaper-root-MuiDrawer-paper {
  color: var(--sidebar-normal-color);
} */
.css-1ti1ykt-MuiPaper-root-MuiPopover-paper {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
}
.css-15ez4hi-MuiPaper-root-MuiAccordion-root.Mui-expanded:last-of-type {
  background-color: var(--background-secondary-color);
  color: var(--text-primary-color);
}
.css-alxk9h-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: var(--text-primary-color);
}
.css-13isf56-MuiInputBase-input-MuiOutlinedInput-input,
.css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--text-primary-color);
}
/* fieldset {
  border-color: var(--portal-theme-secondary) !important;
}
fieldset:focus {
  border-color: var(--portal-theme-primary) !important;
} */
.svg-color svg,
.MuiCalendarPicker-root svg {
  color: var(--portal-theme-primary);
}
/* calendar selected Color */
.css-14ewge7.Mui-selected,
.css-14ewge7.Mui-selected:hover,
.css-rbocvz-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover,
.css-s80gtz-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: var(--portal-theme-primary) !important;
}
.css-1qwrva7-MuiButtonBase-root-MuiListItemButton-root:before {
  background-color: var(--portal-theme-primary);
}
/* calender selected hover */
.css-2tnm32.Mui-selected:hover,
.css-s80gtz-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover {
  will-change: background-color;
  background-color: var(--portal-theme-primary);
}

/* .css-y5nl51-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-y5nl51-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: var(--portal-theme-primary);
} */
.css-1w4063c-MuiTypography-root,
.css-rq00hd-MuiTypography-root {
  cursor: pointer;
}
.css-e9crry-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--text-primary-color);
}
.css-105feo8-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root:hover {
  background-color: var(--portal-theme-primary);
}
.css-105feo8-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root {
  /* background-color: var(--portal-theme-primary); */

  color: var(--background-primary-color);
}
.css-rxrcxl-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--text-primary-color);
}
.css-1bsqz8-MuiPaper-root-MuiDrawer-paper {
  background-color: transparent;
}
.css-1gxhmaf-MuiListItemIcon-root {
  color: var(--portal-theme-primary);
}
.css-x6t0nh-MuiButtonBase-root-MuiIconButton-root {
  color: var(--portal-theme-primary) !important;
}
.css-733kmc-MuiStack-root {
  background-color: var(--sidebars-background-color);
}
.css-jjtu05 {
  background-color: var(--sidebars-background-color);
}
.css-1mav5hp-MuiPaper-root-MuiDrawer-paper {
  box-shadow: none;
}
.css-1qro46r
  .MuiOutlinedInput-root.MuiInputBase-sizeSmall
  .MuiAutocomplete-input {
  padding: 2.5px 4px 2.5px 6px;
  color: white;
}
.css-kd0yc-MuiPaper-root-MuiAutocomplete-paper,
.css-1clbjpy {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
}
.css-1nuss9t {
  color: var(--text-primary-color);
}
.css-40mfy8-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--text-primary-color);
}
.css-lvwv31 {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
  border: 1px solid var(--portal-theme-primary);
}
.css-pt151d {
  color: var(--portal-theme-primary);
}

.css-yzm7vx,
.css-184p1yy,
.css-12ff9sa,
.css-12ff9sa {
  color: var(--text-primary-color);
}
.review-card {
  background-color: var(--background-secondary-color);
  color: var(--text-primary-color);
}

.css-k7695x-MuiPaper-root-MuiAppBar-root {
  background-color: var(--background-primary-color);
  /* z-index: 11111 !important; */
}
.css-3ioe56 {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
}
.css-1hes0mo:hover {
  color: var(--portal-theme-primary);
}
.css-66zhem {
  background-color: var(--background-primary-color);
}
.css-ze1xs7 {
  color: var(--portal-theme-primary) !important;
}
.css-f0u5qy {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
}
.css-12ff9sa {
  color: none;
}
.css-1s2atx1-MuiButtonBase-root-MuiIconButton-root {
  color: var(--portal-theme-primary);
}
.css-r13mc8 {
  color: var(--portal-theme-primary);
}
.new-memories {
  background-color: var(--sidebars-background-color);
  color: var(--portal-theme-primary);
}
.css-yxnldu {
  background-color: var(--sidebars-background-color);
}
/* .css-i4bv87-MuiSvgIcon-root {
  color: var(--button-text-color);
} */
.css-1bfvxec {
  color: var(--text-primary-color);
}
.css-yzm7vx {
  color: var(--text-primary-color);
}
.css-vb81xk-MuiInputBase-root-MuiOutlinedInput-root {
  color: var(--text-primary-color);
}
.css-zuwxiq {
  color: var(--text-primary-color);
}
.css-1v6giby {
  background-color: var(--background-primary-color);
  color: var(--text-primary-color);
}
.css-13d5z5v {
  color: var(--background-primary-color);
  /* background-image: url(../images/btn-img.png); */
}
.css-13d5z5v:hover {
  background-color: var(--portal-theme-primary);
  color: var(--background-primary-color);
}
.css-q6wzhr {
  color: var(--portal-theme-primary);
}
.css-zuwxiq {
  color: var(--text-primary-color);
}
.css-2300kw {
  color: var(--text-primary-color);
}
.program-lock-icon .css-vubbuv {
  fill: var(--portal-theme-primary);
}
.css-vubbuv {
  fill: var(--portal-theme-primary);
}
.css-4cxmk4,
.css-admp4s-MuiButtonBase-root-MuiIconButton-root {
  background-color: var(--portal-theme-secondary);
  color: var(--portal-theme-primary);
}
.card {
  background-color: var(--background-secondary-color);
}
.modal-theme {
  background-color: var(--sidebars-background-color);
}
.css-1xjb83h {
  color: var(--text-primary-color);
}
.css-rmquh4-MuiButtonBase-root-MuiChip-root {
  color: var(--portal-theme-primary);
  border: 1px solid var(--portal-theme-primary);
}
.css-1tir071-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--text-primary-color);
}
.css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--text-primary-color);
}
.css-muvvuh {
  color: var(--text-primary-color);
}
.transaction-screen {
  background-color: var(--background-primary-color);
}
.css-2300kw.Mui-disabled {
  color: var(--text-primary-color);
}
.css-1tc40pv {
  color: var(--input-text-color);
}
.css-ijridm-MuiTypography-root {
  color: var(--portal-theme-primary);
}
.css-3ioe56.Mui-expanded:last-of-type {
  background-color: var(--sidebars-background-color);
}
/* HINA KHAN IN DARK MODE CONVERSION ENDS HERE */

h1 {
  font-size: 40px;
  color: var(--portal-theme-primary);
}
h2 {
  font-size: 30px;
  color: var(--portal-theme-primary);
}
h3 {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.5rem !important;
}
h4 {
  font-size: 1rem;
  font-weight: 500;
}

.normal-text {
  font-size: 14px;
  font-weight: 500;
}
.text-secondary {
  color: var(--text-secondary-color) !important;
}

a:hover {
  text-decoration: none !important;
  color: var(--sidebars-hover-color);
}

legend {
  float: unset;
}

.circular-progress {
  margin-left: 50%;
  margin-top: 20%;
}
.text-area-task {
  background-color: var(--input-background-secondary);
  outline: none;
}
.inputs-fields {
  background-color: var(--input-background-primary);
  /* background-color: green; */
}
.form-control:focus {
  background-color: var(--textarea-color);
  color: var(--text-primary-color);
  border: 1px solid var(--portal-theme-primary);
}
.form-control {
  border: none;
  color: var(--text-primary-color);
}
.programm-card {
  background-color: var(--background-secondary-color);
}

.programm-card h3 {
  color: var(--portal-theme-primary);
}
.programm-card p span {
  color: var(--text-secondary-color);
}
.lesson-card h4 {
  color: var(--portal-theme-primary);
}
.textarea-color {
  background-color: var(--textarea-color);
}
.textarea-block textarea {
  color: var(--text-primary-color);
}
.simplebar-wrapper {
  height: 400px;
  overflow: hidden;
}
.simplebar-content-wrapper {
  overflow: scroll;
  background-color: var(--sidebars-background-color);
}

.task-label {
  font-family: "Montserrat", sans-serif;
}

textarea:hover {
  box-shadow: none;
}

.custom-popover-icon {
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  color: var(--portal-theme-primary) !important;
}

.custom-popover li {
  z-index: 10;
  font-size: 12px;
}

.form-control:focus {
  box-shadow: none;
  border-color: var(--portal-theme-primary);
}

.programme-card-desc {
  margin-top: 0px !important;
  font-size: 14px;
  color: var(--text-primary-color);
}

.programme-duration {
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 0px !important;
  color: var(--text-secondary-color);
}

.lesson-notes-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--text-primary-color);
}

.programme-content {
  padding: 15px 15px 20px 15px;
}

.lesson-content {
  padding: 15px 15px 20px 15px;
}

.hovr-effect:hover {
  color: var(--background-primary-color);
}

.small-contained-button {
  /* background-image: url(../images/btn-img.png); */
  background-color: var(--button-background-color);
  /* background-color: #1a93a9; */
  padding: 8px 16px;
  color: var(--button-text-color);
  border-radius: 5px;
  border: 1px solid var(--button-outlined-color);
}

.small-outlined-button {
  background-color: var(--button-background-color);
  padding: 8px 16px;
  color: var(--background-primary-color);
  border-radius: 5px;
  border: 2px solid var(--portal-theme-primary);
}
.program-btn {
  text-align: left;
  margin-bottom: 20px;
}
.program-category-btn {
  text-align: right;
  margin-bottom: 20px;
}

.form-label-lesson {
  font-weight: bold;
}

.feedback-programme {
  font-size: 25px;
}

.normal-font {
  font-size: 14px;
  font-weight: 500;
}

.feedback-programme-header {
  display: flex;
  justify-content: center;
}

.feedback-programme-text {
  padding: 10px;
  font-weight: bold;
  margin-bottom: 0;
}

.bold-heading {
  font-weight: bold;
}

.section-space {
  margin-top: 0.75rem !important;
}

.rating-stars {
  float: right;
  padding-bottom: 15px;
  padding-right: 15px;
}

.program-font-size {
  font-size: 14px;
  font-weight: 500;
}

.heading-subtitle {
  font-size: 14px;
  font-weight: 700;
}

.icon-style {
  color: var(--text-primary-color);
  float: right;
}

.icon-style:hover {
  color: var(--portal-theme-primary);
  border-radius: 50%;
}

.lesson-card {
  background-color: var(--background-secondary-color);
  cursor: pointer;
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 5px 10px -4px rgb(0 3 82 / 24%);
  color: var(--text-primary-color);
}

.payment-card {
  background-color: var(--background-secondary-color);
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 5px 10px -4px rgb(0 3 82 / 24%);
  color: var(--text-primary-color);
}
.payment-card .request-type {
  font-weight: 500;
}

.data-table {
  color: var(--text-primary-color);
  text-align: center;
}
.data-table p {
  color: var(--text-secondary-color);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}
.data-table .css-n1f7dt-MuiPaper-root-MuiCard-roo {
  border-radius: 0;
}
.css-qen1a1-MuiDataGrid-root {
  border: 2px solid var(--portal-theme-secondary) !important;
}
.data-table .MuiDataGrid-cell,
.css-1sstdvt-MuiDataGrid-columnHeaders {
  border-bottom: 1px solid var(--portal-theme-secondary);
}

.data-table .css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  color: var(--portal-theme-primary) !important;
}
.data-table .MuiDataGrid-menuList,
.css-oau2hf-MuiPaper-root {
  background-color: var(--background-secondary-color) !important;
  color: var(--text-primary-color);
}
.data-table .css-1wph1rq-MuiDataGrid-overlay {
  background-color: var(--background-secondary-color) !important;
}
.loagin-button.Mui-disabled {
  background-color: var(--portal-theme-secondary) !important;
}
.loagin-button .MuiLoadingButton-loadingIndicator {
  color: var(--portal-theme-primary) !important;
}
.loagin-button:hover {
  background-color: var(--portal-theme-secondary);
}
.MuiDataGrid-iconSeparator {
  display: none !important;
}
.date-color {
  color: var(--text-secondary-color) !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.back-screen-button {
  background-color: var(--portal-theme-secondary);
  color: var(--portal-theme-primary);
  margin-bottom: 10px;
}

.media-margin {
  margin-top: 0.75rem !important;
}

.back-arrow-margin {
  margin-bottom: 10px;
}

.css-19kzrtu {
  padding: 0px 0px;
}

.memories-date p {
  color: var(--text-primary-color);
  margin-top: 15px;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  color: var(--portal-theme-secondary) !important;
}
.carousel-nav-button {
  background-color: var(--text-primary-color) !important;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  padding-top: 12px;
  /* padding-right: 5px; */
}
.css-d82q1s-MuiButtonBase-root-MuiButton-root {
  background-color: transparent !important;
  color: var(--text-primary-color) !important;
}

.submit-button {
  text-align: center;
  /* background-image: url(../images/btn-img.png); */
  background-color: var(--button-background-color);
  border: 1px solid var(--button-outlined-color);
  color: var(--button-text-color);

  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 12px;
}
.upload-button label {
  text-align: center !important;
  background-color: var(--portal-theme-secondary);
  padding: 15px;
  color: var(--portal-theme-primary);
  border-radius: 5px;
  cursor: pointer;
  height: 76px;
  width: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview {
  position: relative;
}
.preview img {
  height: 76px;
  width: 76px;
  border-radius: 5px;
  margin-right: 10px;
  display: inline;
}
.preview span {
  position: absolute;
  right: 3px;
  top: -10px;
  font-size: 13px;
  font-weight: bold;
  height: 20px;
  background-color: red;
  width: 20px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
}

.add-photo .preview span,
.add-memories-preview .preview span {
  top: -35px;
}
.preview span:hover {
  cursor: pointer;
}
.new-memories .MuiFormControl-root {
  width: 100%;
}

.event-details {
  margin: 20px auto;
}
.event-details h1 {
  font-size: 30px;
}

.new-memories h1 {
  font-size: 20px;
  margin: 20px auto;
}
#player {
  width: 100% !important;
}
.card-full-width {
  width: 100%;
}
.rating-stars-box {
  position: absolute;
  bottom: 0%;
}
.download-icon {
  margin-top: 12px;
}

.remove-border {
  border: none !important;
  margin: 0 !important;
  z-index: 1111 !important;
}
.remove-border svg {
  z-index: 1 !important;
}
.sidebar-icon svg {
  color: var(--button-text-color) !important;
}
.set-display-inline .remove-border,
.affirmation-dots .remove-border {
  display: inline;
  position: absolute;
  top: 5px;
  color: var(--portal-theme-secondary);
  right: 5px;
  width: 36px;
  height: 36px;
  border-radius: 50% !important;
  background-color: var(--portal-theme-secondary) !important;
  text-align: center;
}

.Ninty-Day-trackerList-dots .remove-border {
  display: inline;
  position: absolute;
  top: 60px;
  color: var(--portal-theme-secondary);
  right: 5px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--portal-theme-secondary);
  text-align: center;
}
.set-image-center img {
  margin: auto;
}

/* .player-wrapper {
  position: relative;
  padding-top: 56.25%;
} */

/* .react-player {
  position: absolute;
  top: 0;
  left: 0;
} */

.wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.accordion-summary svg {
  color: var(--portal-theme-primary);
}

.range-slider span {
  color: var(--portal-theme-primary);
}
.css-1e799a4-MuiListItemIcon-root {
  color: var(--portal-theme-primary);
}
.notes-input {
  padding: 15px;
  padding-right: 30px;
}

.slider-box {
  position: relative;
}
.join-now-btn {
  position: absolute;
  bottom: 25px;
}
.event-slider .slick-prev:before,
.event-slider .slick-next:before {
  color: var(--portal-theme-primary);
}
.event-slider .slick-prev,
.event-slider .slick-next {
  z-index: 1;
}

.programm-svg:hover {
  cursor: pointer;
}
.review-slider .slick-track {
  display: flex;
  flex-wrap: wrap;
}
.review-slider .slick-slide {
  flex: 0 0 auto;
  height: auto !important;
}
.review-slider .slick-slide > div {
  background-color: var(--background-secondary-color);
  border: 1px solid var(--button-outlined-color);
  height: 100%;
  border-radius: 5px;
}
.review-slider .card-border {
  border: none;
}
.dashboard-circle {
  height: 565px;
}
/* chat style Support Tickets*/
.chat-main-card {
  background-color: var(--sidebars-background-color);
  box-shadow: none;
  color: var(--text-primary-color);
}
.chat-main-card .card-title {
  color: var(--portal-theme-primary);
}
.chat-title-head {
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
}
.chat-message-head {
  border-bottom: 1px solid gray;
}
.chat-title-body {
  border-right: 1px solid gray;
}
.chat-message-body {
  /* background-color: rgba(158, 163, 161, 0.08) !important; */
  /* border-top: 1px solid #282727; */
  position: relative;
  width: 100%;
}
.modal-content {
  background-color: var(--sidebars-background-color);
}
.mui-btn-close {
  /* color: var(--button-outlined-color) !important; */
  text-align: right;
}
.mui-btn-close-icon {
  position: absolute;
  right: 3px;
  top: 3px;
}
.chat-main-card hr {
  margin: 0;
}
.chat-side-bar {
  padding-right: 0 !important;
}
.chat-title-body:hover {
  background-color: rgba(158, 163, 161, 0.08) !important;
  cursor: pointer;
}

.uiDataGrid-sortIcon {
  display: none;
}

.open-ticket-color {
  background-color: #007bff;
  color: var(--input-text-color);
  border-color: #007bff;
  width: 90px;
}
.solved-ticket-color {
  background-color: #28a745;
  color: var(--input-text-color);
  border-color: #28a745;
  width: 90px;
}
.waiting-ticket-color {
  background-color: #ffc107;
  color: #000;
  border-color: #ffc107;
  width: 90px;
}

.css-qen1a1-MuiDataGrid-root .MuiDataGrid-cell:focus,
.css-rvx2y1 .MuiDataGrid-cell:focus,
.css-rvx2y1 .MuiDataGrid-columnHeader:focus {
  outline: none;
}
.dx-sort {
  display: none !important;
}
.suppor-ticket-data-table .MuiDataGrid-row,
.cross-icon {
  cursor: pointer;
}
.suppor-ticket-data-table .MuiIconButton-sizeSmall {
  display: none;
}
.suppor-ticket-data-table .MuiDataGrid-cell:focus,
.suppor-ticket-data-table .MuiDataGrid-columnHeader:focus {
  outline: none;
}
.suppor-ticket-data-table .MuiDataGrid-columnHeaders {
  border: 2px solid var(--portal-theme-secondary) !important;
  color: var(--portal-theme-primary) !important;
}
.suppor-ticket-data-table {
  border: 2px solid var(--portal-theme-secondary) !important;
}
.suppor-ticket-data-table .MuiDataGrid-iconSeparator {
  cursor: pointer;
}
.suppor-ticket-data-table .MuiDataGrid-virtualScrollerContent {
  min-height: 200px !important;
}
.custom-popover-box .remove-border {
  right: 5px;
  width: 30px;
  position: absolute;
  top: 40px;
}
.ticket_image_preview img:hover {
  cursor: pointer;
}
.SnackbarItem-action {
  display: inline;
  max-width: 80px;
  vertical-align: middle;
  position: absolute;
  right: 10px;
}

/* for datepicker background on mobile */
.MuiDialog-paperScrollPaper {
  background-color: var(--popup-background-color);
  color: var(--input-text-color);
}
/* end chat style Support Tickets*/

.associate-section {
  padding-left: 40px;
  color: var(--text-primary-color);
}
@media only screen and (max-width: 375px) {
  /* .custom-video-player {
    height: 100% !important;
  } */
  .lesson-heading {
    padding-top: 10px;
  }
  .card-spacing {
    margin-top: 0.75rem;
  }
  .mobile-margin {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .preview img {
    height: auto;
  }
  .fc-button-primary {
    padding: 3px !important;
  }
  .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    margin-left: 0;
  }
  .fc-toolbar-title {
    font-size: 16px !important;
  }
}

/*=================================  full calender  =======================*/

.calender-events-box {
  margin-top: 10px;
}

.calender-events-text {
  padding: 2px 6px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
}
.fc-daygrid-day-frame {
  overflow: hidden;
}

.fc-daygrid-day-events {
  padding-top: 1rem;
  color: white;
}

.calender-events-text {
  color: white;
}

.full-calendar .fc-day-today {
  /* background-color: var(--calendar-selected-box) !important; */
}
.full-calendar .fc-more-popover {
  /* background-color: var(--calendar-selected-box); */
  z-index: 1;
}
.fc-toolbar-chunk {
  display: inline-flex;
}
.fc-day-grid-event > .fc-content {
  white-space: normal;
}

.eventimage {
  width: 50px;
  height: 50px;
  margin-left: 5px;
  border: solid;
  border-color: var(--portal-theme-primary);
}

.fc-daygrid-event {
  border: none !important;
  background: none !important;
}

.fc-today-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: none !important;
  font-weight: bold !important;
}

.fc-prev-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: none !important;
  box-shadow: none !important;
  border: 1px solid var(--portal-theme-primary) !important;
}

.fc-right {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: none !important;
  box-shadow: none !important;
}

.fc-next-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: none !important;
  box-shadow: none !important;
  border: 1px solid var(--portal-theme-primary) !important;
}

.fc-dayGridMonth-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: solid !important;
  border-color: var(--portal-theme-primary) !important;
  box-shadow: none !important;
}

.fc-timeGridWeek-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: solid !important;
  border-color: var(--portal-theme-primary) !important;
  box-shadow: none !important;
}

.fc-timeGridDay-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: solid !important;
  border-color: var(--portal-theme-primary) !important;
  box-shadow: none !important;
}

.fc-col-header-cell-cushion {
  color: var(--portal-theme-primary);
  text-decoration: none;
}

.fc-scrollgrid-section {
  border-style: none;
}

.fc-daygrid-day-number {
  text-decoration: none;
  color: var(--text-primary-color);
}

.fc-daygrid-day-top {
  float: left !important;
}

.fc-scrollgrid-sync-table {
  border-top: thick !important;
}

.dropdownbutton {
  border-color: #ccc5c5 !important;
  font-size: 12px !important;
}

.dialog-img {
  border: none;
  z-index: 1;
  top: 60%;
  left: "center";
}

.dialog-image {
  width: 400px;
  height: 400px;
}

.dialog-detail {
  border-color: #42969c;
  border-radius: 5px;
  position: static;
  z-index: 1;
  top: 100%;
  left: 20%;
  width: 400px;
}

.log-detail {
  width: 120px;
}
.social-set {
  border: solid;
  border-color: #ccc5c5;
  border-radius: 50px;
  display: flex;
}
.dl-button {
  border: 1px solid #ccc5c5;
  padding: 5px;
  display: flex;
}
.dl-button2 {
  border: 1px solid var(--background-primary-color);
  padding: 5px;
  display: flex;
}

/* pinterest layout design */

.pinterest-card span {
  color: var(--text-secondary-color);
  font-size: 14px;
  font-weight: 600;
  margin-top: 15px;
  display: block;
  padding: 0px 15px;
}

#columns {
  column-width: 280px;
  column-gap: 15px;
  width: 100%;
  max-width: 1100px;
  margin: 50px auto;
  margin-top: 0;
}

div#columns div {
  background-color: var(--background-secondary-color);
  border-radius: 16px;
  margin-bottom: 15px;
}
div.show-pointer div:hover {
  cursor: pointer;
}
.pinterest-card {
  position: relative;
}
div#columns figure {
  margin: 0 2px 15px;
  transition: opacity 0.4s ease-in-out;
  border-radius: 16px;
  display: inline-block;
  width: 99%;
  padding-bottom: 0px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 0;
}

div#columns figure img {
  width: 100%;
  height: auto;
  margin-bottom: 5px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

div#columns figure p {
  margin: 4px 0;
  padding: 15px 15px 20px;
  text-align: left;
  padding-bottom: 0;
  margin-bottom: 15px;
}

div#columns small {
  font-size: 1rem;
  float: right;
  text-transform: uppercase;
  color: #aaa;
}

div#columns small a {
  color: #666;
  text-decoration: none;
  transition: 0.4s color;
}

/* div#columns:hover figure:not(:hover) {
  opacity: 0.4;
} */

@media screen and (max-width: 750px) {
  #columns {
    column-gap: 0px;
  }
  #columns figure {
    width: 100%;
  }
}
/* pinterest end */

.profile-details {
  font-weight: 500;
  font-size: 14px;
  margin-top: 12px;
}
.profile-details-padding {
  padding-left: 35px !important;
  padding-right: 35px !important;
}
.profile-heading {
  font-size: 30px;
  padding-left: 35px;
}
.add-category-button {
  margin-right: 44px;
  margin-bottom: 15px;
}

.quotes-heading {
  line-height: 1.235;
  margin-bottom: 25px;
}

.container h2,
.event-title h6 {
  color: var(--portal-theme-primary);
}

.dashboard-heading {
  color: var(--portal-theme-primary);
  margin: 0px;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 2.125rem;
  line-height: 1.235;
  margin-bottom: 40px;
}
.add-button {
  text-align: right;
  padding-top: 0.25rem;
}
.drawer-section {
  max-width: 400px;
}
.MuiDrawer-paperAnchorRight {
  width: 400px !important;
}
.drawer-padding {
  padding: 0 !important;
}
.drawer-cross-btn {
  margin-top: 12px;
  padding: 6px;
  margin-bottom: 0px !important;
}
.drawer-cross-btn svg {
  font-size: 16px;
}
.custom-video-player iframe {
  height: 100% !important;
}
.react-audio-player {
  width: 100%;
}
button.disabled:hover {
  cursor: not-allowed;
}
.fc-theme-standard .fc-popover-header {
  background: rgba(208, 208, 208, 0.3);
  background: var(--fc-neutral-bg-color, rgba(208, 208, 208, 0.3));
  background-color: black;
}
.fc .fc-more-popover .fc-popover-body {
  min-width: 220px;
  padding: 10px;
  background: black;
}
.no-access-string {
  width: 100%;
  text-align: center;
  padding: 20px;
}
.css-qs0bdh-MuiStack-root {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0 33px;
}
.no-access-string p {
  margin-bottom: 0 !important;
}
.download-icon {
  text-align: end;
  margin-bottom: 20px;
}
.download-icon .icon-style {
  color: var(--portal-theme-primary);
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  margin-top: 40px;
  right: 10px;
}
.download-icon span {
  height: 20px !important;
  width: 20px !important;
}
.section-button button {
  /* display: block; */
  background-color: #145b6c;
  color: #fff;
  border: none;
  padding: 8px 20px;
  border-radius: 5px;
}
.dot-btn {
  position: absolute;
  right: 0;
  top: 5px;
}
.set-max-height {
  max-height: 200px !important;
}
.goal-statement-messages .tab {
  margin-top: 10px;
}

.responce-messages .set-image-width {
  width: 23% !important;
  padding-left: 0% !important;
  padding-right: 0% !important;
}

/* .event-title {
  background-color: var(--sidebars-background-color);
} */
.SnackbarItem-message {
  padding-right: 40px !important;
}
.SnackbarItem-action svg,
.SnackbarItem-message svg {
  color: var(--input-text-color) !important;
}
swd-pin-field {
  display: flex;
  justify-content: space-between;
}
.responce-messages .set-title-width {
  width: 57% !important;
}
.forms-drawer .simplebar-wrapper {
  height: 100% !important;
}
.circle-image .set-title-width span {
  color: var(--text-primary-color);
}
.circle-image .MuiAvatar-root {
  width: 60px;
  height: 60px;
}
.css-15ez4hi-MuiPaper-root-MuiAccordion-root:last-of-type {
  border-radius: 10px !important;
  background-color: var(--background-secondary-color);
  color: var(--text-primary-color);
}

.password-tooltip {
  border: 2px solid var(--portal-theme-primary) !important;
  color: var(--portal-theme-primary) !important;
}
.carousel-container a {
  text-decoration: none;
}
.simplebar-content .css-1gtfl7l {
  width: 100% !important;
  z-index: 1111111111;
}

.css-17mra34-MuiPaper-root-MuiCard-root {
  box-shadow: none;
}
.zoom-password svg {
  font-size: 16px;
  margin-left: 5px;
}

.snackbar-cross-icon svg {
  color: #fff;
  font-size: 16px;
}
.recordings-card {
  background-color: var(--background-secondary-color);
  margin-bottom: 20px;
  padding: 20px;
}

audio::-webkit-media-controls-panel {
  width: 100% !important;
  background-color: var(--audio-color) !important;
  border-radius: 50px;
}

.dashboard_description img {
  margin: 0px auto !important;
}
/* .dashboard_description p {
  color: var(--text-primary-color) !important;
} */
.image-slider {
  margin: 50px 50px 10px 50px !important;
}
.image-slider .image-gallery-icon svg {
  height: 40px;
  width: 40px;
  font-size: 16px;
  color: var(--button-text-color);
  background-color: var(--portal-theme-secondary);
  border-radius: 50%;
}
.image-slider .image-thumbnails {
  display: flex;
  justify-content: center;
}

.image-slider .main-image img {
  max-height: 400px;
}
.disable-pointer {
  cursor: not-allowed !important;
}
.image-slider .image-thumbnails img {
  width: 60px;
  height: 50px;
  cursor: pointer;
}
.image-slider .image-thumbnails .is_selected {
  border: 1px solid var(--portal-theme-primary);
  padding: 3px;
}
.sidebar-drawer {
  background-color: var(--background-primary-color);
}

.date-picker button {
  background-color: red;
}

.goal_achieved_lower_text {
  display: none;
}
@media screen and (max-width: 1024px) {
  .quotes-heading {
    margin-bottom: 40px;
  }
  .add-category-button {
    margin-right: 20px;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 769px) {
  .profile-button-padding {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .add-category-button {
    margin-right: 40px;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 576px) {
  .add-button {
    text-align: center;
    padding-top: 0;
    margin-bottom: 30px;
  }
  .MuiDrawer-paperAnchorRight {
    width: 100% !important;
  }
  .lesson-card-image {
    width: 100%;
  }
  .kims-reply-card .set-image-width {
    width: 23% !important;
  }
  .kims-reply-card .set-title-width {
    width: 57% !important;
  }
  .program-btn,
  .program-category-btn {
    text-align: center;
  }

  .goal_achieved_lower_text {
    display: block;
  }
  .goal_achieved_upper_text {
    display: none;
  }
  .dashboard-circle {
    height: 325px;
  }
  .css-qen1a1-MuiDataGrid-root {
    border-left: none !important;
    border-right: none !important;
    padding: 0 10px;
  }
  header img {
    width: 100% !important;
  }
  .SnackbarItem-message {
    max-width: 100%;
  }
  .support-ticket-button-contained,
  .support-ticket-button-outlined {
    margin-top: 20px;
  }
}

.fc-more-link,
.fc-button-active {
  color: var(--portal-theme-primary) !important;
}

@media screen and (max-width: 376px) {
  .profile-button {
    margin-bottom: 20px;
    width: 150px;
    margin-left: -10px;
  }
  .profile-image {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .profile-heading {
    font-size: 30px;
    padding-left: 0px;
  }
  .profile-button-padding {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .profile-details-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .add-category-button {
    margin-right: 19px;
    margin-bottom: 15px;
  }
}

/* Comunity */

.new-community-page .banner-section {
  /* padding-top: 20px; */
}

.new-community-page .slick-arrow {
  display: none !important;
}

.new-community-page .jade-img {
  border: 4px solid #eebe60;
  border-radius: 12px;
  display: inline;
}

.new-community-page .jade-div {
  position: absolute;
  bottom: -10px;
  left: 30px;
}

.new-community-page .img-left {
  margin-left: -25px;
  padding-top: 66px;
  display: inline;
}

/* membership-section  */
.new-community-page .back-light-div {
  background: #1d1c1d;
  border-radius: 10px;
}

.new-community-page .border-right {
  border-right: 3px solid #eebe60;
}

.favourite-box {
  height: 40px;
  width: 40px;
  border: 1px solid #ffff;
  text-align: center;
  padding: 5px;
  border-radius: 4px;
  font-size: 8px;
}

.score-row img {
  width: 30px;
}

.favourite-box img {
  display: inline;
}

.favourite-box p {
  margin-bottom: 0;
}

.new-community-page .membership-section button {
  border: 1px solid #fff;
  border-radius: 7px;
  height: 29px;
  width: 85%;
  background: transparent;
  color: #fff;
  text-decoration: none;
  font-size: 10px;
}

.new-community-page .position-img {
  position: absolute;
  right: -13px;
  top: -60px;
}

.new-community-page .position-img img,
.new-community-page .position-img-1 img {
  height: 110px;
  width: 1px;
}

.board-main-image-box,
.board-inner-image-box,
.leader-board-component {
  position: relative;
}

.leader-board-component .leader-board-info-icon {
  position: absolute;
  right: 7px;
  top: 7px;
  width: 13px;
}

.board-main-image-box .board-image-icon {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.board-inner-image-box .board-image-icon {
  position: absolute;
  left: -6px;
  bottom: -3px;
}

.search-input input {
  background-color: #262626 !important;
  margin-bottom: 10px;
  font-size: 13px;
  border-radius: 20px;
  border: none !important;
  height: 35px;
  padding-left: 15px;
}

.favourite-buttton-box button {
  background-color: #fff !important;
  border: none !important;
  margin-bottom: 10px;
  font-size: 13px;
  color: #000 !important;
}

.favourite-buttton-box svg {
  color: var(--button-text-color);
  font-size: 20px;
  margin-top: -3px;
}

.new-community-page .position-img-1 {
  position: absolute;
  right: 6px;
  top: -60px;
}

.open-create-post-card .profile-cards {
  min-height: auto;
}
.input-with-profile img {
  border-radius: 50%;
  margin-right: 2%;
}

.input-with-profile .input-field {
  background-color: #262626;
  width: 85%;
  border-radius: 30px;
  border: none;
  padding: 0 10px;
  line-height: 37px;
  cursor: pointer;
  font-size: 14px;
}

.search-input ::placeholder {
  color: #fff;
}
.search-input textarea {
  background-color: #262626 !important;
  margin-bottom: 10px;
  font-size: 13px;
  border-radius: 5px;
  border: none !important;
  padding-left: 15px;
}

.likes-post {
  justify-content: start;
  font-size: 13px;
}

.likes-post .MuiAvatarGroup-avatar {
  width: 12px;
  height: 12px;
  font-size: 11px;
}

.likes-post img {
  display: inline;
  height: 15px;
  width: 16px;
}

.likes-post svg {
  font-size: 20px;
}

.likes-post span:hover {
  text-decoration: underline;
  cursor: pointer;
}
.upload-video .MuiChip-root,
.comment-icons .MuiChip-root {
  width: 100%;
  cursor: pointer;
}
.comment-icons .MuiChip-root {
  border: none;
}

.likes-post .MuiAvatar-colorDefault {
  display: none !important;
}

.comment-icons .MuiChip-root:hover {
  background-color: #262626;
}

.upload-video .MuiChip-root svg,
.comment-icons .MuiChip-root svg {
  font-size: 20px;
  margin-left: 3px;
  fill: #fff;
}

.upload-video .MuiChip-root span,
.comment-icons .MuiChip-root span {
  color: #fff !important;
  font-size: 12px !important;
}

.post-detail-page .comment-icons .MuiChip-root {
  width: 130px;
  cursor: pointer;
}
.post-detail-page .comment-icons .MuiChip-label {
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-size: 10px !important;
}
.view-comment span:hover {
  cursor: pointer;
  text-decoration: underline;
}

.new-community-page .img-back-border img {
  display: inline;
  width: 50px;
}
.new-community-page .img-back-border {
  /* background: url(../images/back-batch.png); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 5px 15px 4px 11px;
  text-align: center;
}

/* success-wrapper  */
.new-community-page .success-wrapper {
  padding-top: 80px;
}

.new-community-page .success-wrapper h1 {
  font-size: 25px;
  font-weight: 600;
  color: #fff;
}

.new-community-page .success-wrapper h2 {
  font-size: 15px;
  font-weight: 300;
  margin: 13px 0px;
  color: #fff;
}

.new-community-page .success-wrapper span {
  color: #93b36c;
}

.new-community-page .success-wrapper p {
  line-height: 2;
}

.new-community-page .btn-wrapper button {
  border: none;
  padding: 4px 20px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
}

.new-community-page .btn-wrapper .play-btn {
  background: #fff;
  color: #000;
}

.new-community-page .btn-wrapper .more-info-btn {
  background: #555454;
  color: #fff;
}

.new-community-page .slider-lower-buttons button {
  border: 1px solid #fff;
  border-radius: 7px;
  height: 29px;
  background: transparent;
  color: #fff;
  text-decoration: none;
  font-size: 10px;
  font-weight: 100;
  letter-spacing: 3px;
  width: 130px;
  text-align: left;
  padding-left: 10px;
}

.new-community-page .activity-profile img {
  width: 30px;
  margin-top: 5px;
}

.new-community-page .wealth-wallet {
  position: relative;
}
.new-community-page .wealth-wallet .i-icon {
  position: absolute;
  right: 10px;
  top: 5px;
  width: 20px;
  cursor: pointer;
}
.score-right-side h2 {
  margin-bottom: 0.8rem;
  color: #d74336;
}
.score-right-side h5 {
  margin-bottom: 0.8rem;
}

.main-section {
  position: relative;
}
.post-detail-image iframe {
  width: 100% !important;
}
.main-section hr {
  margin: 7px auto;
}
.new-community-page .info-popup-box {
  position: absolute;
  left: -30px;
  top: -100px;
  padding: 5px;
  background-color: #000;
  border-radius: 10px;
  z-index: 2;
  max-width: 350px;
}

.new-community-page .info-popup-box .info-card {
  padding: 20px;
  border: 2px solid #fff;
  border-radius: 10px;
  position: relative;
}

.new-community-page .cross-sigh {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 5px;
}

.new-community-page .cross-sigh span {
  padding: 0px 1px 4px;
  border: 2px solid #fff;
  border-radius: 5px;
}

.new-community-page .cross-sigh span svg {
  fill: #fff;
  color: #fff;
  padding: 2px;
}

.new-community-page .info-popup-box .info-card h2 {
  color: #fff;
  font-size: 20px;
}

.new-community-page .info-popup-box .info-card p {
  /* color: #fff; */
  font-size: 8px;
}

.new-community-page .join-today-btn {
  color: #000;
  background-color: var(--portal-theme-primary);
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 7px;
  cursor: pointer;
  margin-top: 80px;
}
.new-community-page .wealth-wallet h5,
.new-community-page .wealth-wallet span {
  font-size: 10px;
}
.new-community-page .wealth-wallet span {
  color: #28a745;
}
.new-community-page .wealth-wallet h2 {
  font-size: 12px;
}

.new-community-page .community-current-pods p {
  font-size: 8px;
}

.new-community-page .community-current-pods svg {
  font-size: 20px;
}

.new-community-page .live-svg svg {
  color: #93b36c;
}

.new-community-page .starting-svg svg {
  color: #ffc107;
}

.new-community-page .total-points .icon-image img {
  width: 30px;
  margin-top: 7px;
}

.new-community-page .upcoming-events p {
  font-size: 7px;
  color: var(--text-secondary-color);
}

.new-community-page .community-star-icon img {
  width: 40px;
  margin: 0 auto;
}

.new-community-page .comment-icons img {
  display: inline;
  width: 16px;
  margin: 0;
}

.community-slider,
.increase-scale {
  position: relative;
}

.increase-scale svg {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 30px;
  color: #000;
}

.increase-scale .star-icon svg {
  color: var(--button-text-color);
}

.community-slider .increase-scale img {
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.community-slider .increase-scale img:hover {
  transform: scale(1.3);
}

/* .community-slider .increase-scale {
  z-index: 11;
  transform: scale(5);
  top: 20px;
} */

.hide-on-desktop {
  display: none;
}

.create-post-title-box {
  position: relative;
}

.create-post-title-box span {
  position: absolute;
  right: -10px;
  top: -10px;
  height: 30px;
  width: 30px;
  font-size: 16px;
  border-radius: 50%;
  padding-top: 1px;
  background-color: var(--portal-theme-secondary);
}

.create-post-title-box h2 {
  color: #fff;
}

.create-post-title-form .upload-button label {
  width: 100%;
  height: auto;
}

.create-post-title-form .upload-button {
  position: relative;
}

.create-post-title-form .upload-button span {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 25px;
  height: 25px;
  background-color: #1d1c1d;
  font-size: 16px;
  border-radius: 50%;
}
.show-all-likes .post-creator .creator-name span {
  font-size: 12px !important;
}
.post-creator {
  display: flex;
}
.post-creator .creator-name h3 {
  font-size: 13px;
}
.post-creator img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.post-creator select {
  padding: 1px 5px;
  background-color: transparent;
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
}

.post-creator select option {
  background-color: var(--sidebars-background-color);
}
.show-all-likes .post-creator .creator-name h3 {
  color: #fff;
  text-align: start;
  margin-bottom: 0 !important;
}
.video-image-selection {
  padding: 10px;
  border-radius: 5px;
  display: flex;
}

.video-image-selection .selection-box {
  background-color: var(--portal-theme-secondary);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  margin-right: 10px;
  padding-top: 9px;
  cursor: pointer;
}

.video-image-selection svg {
  color: var(--input-text-color);
  font-size: 20px;
  fill: var(--input-text-color);
}

.video-image-selection .selected svg {
  color: var(--button-text-color);
  fill: var(--button-text-color);
}

.video-url-field {
  position: relative;
}

.video-url-field .video-url-cross {
  position: absolute;
  right: -4px;
  top: -8px;
  width: 20px;
  height: 20px;
  background-color: var(--portal-theme-secondary);
  font-size: 13px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.post-submit-btn {
  padding: 5px 30px;
}

@media (max-width: 1024px) {
  .new-community-page .membership-section a {
    padding: 7px 5px;
  }

  .new-community-page .membership-section button {
    font-size: 9px;
  }
}

.support-ticket-comment-message a {
  color: var(--button-text-color);
  text-decoration: none;
}
.time_color {
  color: white !important;
}
.css-1rssnjk-MuiAutocomplete-root
  .MuiOutlinedInput-root.MuiInputBase-sizeSmall
  .MuiAutocomplete-input {
  color: white !important;
}
.search-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.team-member {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  box-shadow: 0 0 2px 0 rgba(81, 86, 248, 0.24),
    0 5px 10px -4px rgb(0 3 82 / 24%);
}
.css-hdw1oc {
  display: none !important;
}
.recommended {
  font-size: 12px;
  /* color: grey; */
}
.recommended-type {
  font-size: 12px;
  color: grey;
}
.tox .tox-notification--warn,
.tox .tox-notification--warning {
  display: none !important;
}

.tox-notifications-container {
  visibility: hidden !important;
}
/* MUI Card */
.mui-custom-card {
  background-color: var(--background-secondary-color);
}

.mui-custom-card h3 {
  color: var(--portal-theme-primary);
}
.mui-custom-card-content {
  padding: 15px 15px 20px 15px;
}

.mui-custom-card-description {
  margin-top: 0px !important;
  font-size: 14px;
  color: var(--text-primary-color);
}
.live-comments {
  position: relative;
  background-color: transparent;
  border: 1px solid #101010;
}

.chat-image-preview,
.add-event-comment-text-field {
  position: relative;
}
.comment-cancel-update {
  position: absolute;
  top: 0px;
  right: -5px;
  height: 20px;
  width: 20px;
}
.comment-cancel-update svg {
  width: 15px;
}

.chat-image-preview img {
  height: 50px;
  width: 50px;
  border-radius: 5px;
  margin-right: 10px;
  display: inline;
}
.chat-image-preview span {
  position: absolute;
  right: 3px;
  top: -10px;
  font-size: 13px;
  font-weight: bold;
  height: 20px;
  background-color: red;
  width: 20px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
}
.bottom-add-comment {
  /* position: absolute; */
  /* bottom: 0px; */
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  background-color: var(--background-secondary-color);
  /* z-index: 10; */
}
.event-chat-emoji-picker-button img {
  width: 23px;
  height: 23px;
  cursor: pointer;
}
.event-chat-emoji-picker-button {
  padding: 6px;
  background-color: var(--portal-theme-secondary);
  border-radius: 50%;
  width: 35px;
}

.event-chat-upload-button label {
  text-align: center !important;
  background-color: var(--portal-theme-secondary);
  padding: 10px;
  color: var(--portal-theme-primary);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-chat-upload-button label img {
  width: 15px;
  height: 15px;
}
.event-chat-send-button {
  padding: 9px;
  background-color: var(--portal-theme-secondary);
  border-radius: 50%;
}
.event-chat-send-button img {
  width: 17px;
  height: 17px;
}
.live-comments-list {
  height: 400px;
  overflow-y: auto;
}
.profile-comments .affirmation-dots .remove-border {
  top: 5px;
  width: 28px;
  height: 28px;
}

.profile-comments .affirmation-dots .remove-border svg {
  font-size: 16px !important;
}

.profile-comments.inception {
  background-color: var(--sidebars-background-color);
  color: #fff;
}
.poster-name .card-title {
  font-size: 14px;
}

.poster-name .date-color {
  font-size: 11px !important;
}
.post-description {
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  margin-bottom: 10px;
}

.post-description p {
  font-weight: 300;
  display: inline;
  font-size: 14px;
}

.post-description a {
  color: var(--button-text-color);
  text-decoration: none;
  z-index: 1;
}

.post-description span {
  z-index: 111;
  color: var(--button-text-color);
}
.inception .post-description {
  margin-bottom: 4px !important;
}

.inception .post-description span p {
  font-size: 14px !important;
  font-weight: 400;
  color: var(--text-secondary-color);
}
.inception .post-description span {
  font-size: 13px !important;
  font-weight: 400;
  color: var(--button-text-color);
  z-index: 0;
}
.live-event-comment-message {
  margin-top: 5px;
}
.favourite-videos-list,
.feed-image,
.post-description {
  cursor: pointer;
}
.feed-image img {
  width: 100%;
  object-fit: cover;
}

.single-comment-card {
  background-color: #282728;
  padding: 10px;
  border-radius: 3px;
}

.single-comment-card .post-description {
  margin-bottom: 0;
}

/* .single-comment-card-dots .remove-border {
  top: 0 !important;
} */

.single-comment-card .card-title {
  font-size: 14px;
}
.inception .affirmation-dots .remove-border {
  top: 4px;
  right: -2px;
  width: 20px;
  height: 20px;
}

.inception .affirmation-dots .remove-border svg {
  font-size: 14px !important;
}
.video-container {
  max-width: 100%;
  margin: 0 auto;
}
iframe {
  max-width: 100% !important;
}
.live-comments {
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #555;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
/* feeds */

@media (max-width: 912px) {
  .new-community-page .img-back-border-1 {
    /* background: url(../images/back-batch.png); */
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 9px 5px 9px 0px;
  }

  .new-community-page .img-border {
    height: 88px;
  }

  .new-community-page .join-today-btn {
    margin-top: 100px;
  }

  .input-with-profile .input-field {
    line-height: 47px;
  }

  .favourite-box {
    height: 100px;
    width: 100px;
    font-size: 16px;
  }

  .score-right-side h2 {
    margin-bottom: 1.8rem;
    color: #d74336;
  }

  .new-community-page .wealth-wallet h2 {
    font-size: 16px !important;
  }

  .score-right-side h5 {
    font-size: 16px !important;
    margin-bottom: 1.8rem;
  }

  .score-row img {
    width: 20px;
  }

  .hide-on-desktop {
    display: block;
    position: absolute;
    padding: 5px;
    background-color: #000;
    border-radius: 10px;
    z-index: 2;
    max-width: 350px;
    left: auto !important;
    top: 30px !important;
    right: 75px;
  }

  .show-on-desktop {
    display: none;
  }

  .comment-icons .MuiChip-label {
    padding-left: 0 !important;
    padding-right: 0 !important;
    font-size: 12px !important;
  }
}

@media (max-width: 820px) {
  .new-community-page .wealth-wallet h5 {
    font-size: 16px;
  }

  .community-card-title h4 {
    font-size: 20px;
  }

  .new-community-page .activity-profile img {
    margin-top: 15px;
  }

  .community-card-title h6 {
    font-size: 18px;
  }

  .score img {
    display: inline;
  }

  .new-community-page .wealth-wallet h2 {
    font-size: 20px;
    margin-top: 20px;
  }

  .new-community-page .community-current-pods p {
    font-size: 16px;
  }

  .community-card-title h5 {
    font-size: 20px;
  }

  .new-community-page .upcoming-events p {
    font-size: 17px;
  }

  .total-points p {
    font-size: 30px;
  }

  .new-community-page .total-points .icon-image img {
    width: 60px;
    margin-top: 7px;
    margin-left: 30px;
  }

  .comment-image img {
    height: 70px;
    width: 70px;
    margin-right: 20px;
  }

  .community-card-title .card-title {
    font-size: 20px;
  }

  .reduce-margin-from-para {
    font-size: 16px;
    font-weight: 600;
  }

  .poster-name .card-title {
    font-size: 16px;
  }

  .poster-name .date-color {
    font-size: 14px !important;
  }

  .new-community-page .wealth-wallet .i-icon {
    position: absolute;
    right: 50px;
    top: 25px;
  }

  .new-community-page .wealth-wallet span {
    font-size: 16px;
  }

  .new-community-page .community-current-pods svg {
    font-size: 30px;
  }

  .community-star-icon {
    text-align: center;
  }

  .new-community-page .join-today-btn {
    font-size: 16px;
    padding: 4px 10px;
  }

  .wider-card .mui-card-button,
  .mui-card-button {
    left: 27%;
  }
}

@media (max-width: 540px) {
  .new-community-page .success-wrapper {
    padding-top: 33px;
  }

  .new-community-page .slider-lower-buttons {
    text-align: center;
  }

  .favourite-box {
    width: 80px;
    height: 80px;
    font-size: 14px;
  }

  .score-right-side h2 {
    margin-top: 0 !important;
  }

  .new-community-page .comment-icons {
    padding: 0 5px;
  }

  .likes-post {
    font-size: 11px;
  }

  .post-detail-page .main-section {
    max-height: 100vh;
    overflow: auto;
  }

  .community-main-section {
    overflow: auto;
  }
}
@media (max-width: 375px) {
  .favourite-box {
    width: 60px;
    height: 60px;
    font-size: 14px;
  }

  .btn-wrapper button {
    font-size: 13px;
  }

  .score-right-side h2,
  .score-right-side h5 {
    margin-bottom: 0.8rem;
  }

  .score-row img {
    width: 40px;
  }

  .community-card-title h4 {
    font-size: 13px;
  }

  .community-card-title h6 {
    font-size: 14px;
  }

  .new-community-page .wealth-wallet h5 {
    font-size: 13px;
  }

  .community-card-title h5 {
    font-size: 16px;
  }

  .new-community-page .community-current-pods p {
    font-size: 12px;
  }

  .new-community-page .upcoming-events p {
    font-size: 12px;
  }

  .favourite-buttton-box {
    margin-top: 15px;
  }

  .community-card-title .card-title {
    font-size: 14px;
  }

  .reduce-margin-from-para {
    font-size: 13px;
  }

  .new-community-page .likes-post img {
    display: inline;
    width: 12px;
    margin: 0;
    height: 12px;
  }
}
.feed-detail-popup {
  background-color: #000;
  height: 100vh !important;
  /* overflow-y: scroll; */
}
.feed-detail-popup .profile-cards {
  height: 99vh;
  border-radius: 0;
  box-shadow: none;
  overflow: auto;
}
.new-community-page .profile-cards {
  cursor: auto;
}
.emoji-picker-button-creat-post {
  position: absolute;
  top: 0;
  right: 0;
}
.emoji-picker-popup {
  width: 300px;
}

.emoji-picker-popup em-emoji-picker {
  height: 200px;
  width: 300px;
}
.create-post-images span {
  right: 13px;
}
.post-detail-page .main-section {
  max-height: 80vh;
  overflow: auto;
}
.poster-logo img {
  width: 45px;
}
.feed-level .MuiChip-root {
  color: var(--portal-theme-primary) !important;
  height: 16px;
  font-size: 10px;
  margin-top: 2px;
}
.dynamite-level-image {
  height: 30px !important;
  width: 34px !important;
  margin-top: -4px;
  padding-left: 6px;
  padding-top: 3px;
}
.clear-both {
  clear: both;
}
.feed-detail-popup .comment-icons .MuiChip-label {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.feed-detail-popup .comment-icons .MuiChip-label img {
  display: inline;
  width: 16px;
}
.comment-icons img {
  width: 16px;
  height: 16px;
}
.comment-icons {
  padding: 0px;
}
.user-info-card .date-color {
  font-size: 12px !important;
}
.like-profile-image {
  position: relative;
}

.like-profile-image .tiny-like-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  right: -10px;
  bottom: -5px;
}

.like-profile-image .tiny-like-icon img {
  width: 13px;
  height: auto;
}
.tiny-like-icon-notification img {
  width: 16px !important;
}
.MuiPopover-paper {
  background-color: var(--sidebars-background-color) !important;
  color: #fff;
}
.likes-model-tabs {
  padding: 10px;
}

.likes-model-tabs img {
  margin-right: 8px;
  width: 16px;
  height: 14px;
  margin-top: 2px;
}

.show-all-likes-box {
  overflow: hidden;
}
.cross-show-all-likes-model {
  position: absolute;
  right: 15px;
  top: 10px;
  height: 33px;
  width: 33px;
  font-size: 20px;
  border-radius: 50%;
  padding-top: 1px;
  background-color: var(--portal-theme-secondary);
  z-index: 1;
  text-align: center;
  cursor: pointer;
}
.post-detail-box .cross-show-all-likes-model {
  position: absolute;
  left: 20px;
  top: 20px;
  text-align: center;
  cursor: pointer;
  padding: 1px;
  background-color: #000000c2;
}
.post-detail-box {
  position: relative;
}
.show-all-likes-tabs img {
  width: 16px;
}
.feed-activity-box {
  max-height: 350px;
  overflow: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.feed-activity-box::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.feed-activity-box {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.load-more-text {
  cursor: pointer;
}
.detail-page-box .profile-main-comments {
  height: 210px;
  overflow: auto;
}

.popup-detail-box .profile-main-comments {
  height: 315px;
  overflow: auto;
}
.add-event-comment .comment-submit-button {
  padding: 4px 6px;
}
.like-comment span {
  color: var(--text-secondary-color);
  margin-right: 10px;
  margin-top: 5px;
}

.like-comment span:hover {
  text-decoration: underline;
}
.like-reply-box span {
  color: #dcdada;
}
.liked-comment {
  color: var(--portal-theme-primary) !important;
}
.comment-like-heart .comment-like-heart-count {
  min-width: 20px;
  text-align: center;
  border: 1px solid #333232;
  border-radius: 50%;
  padding: 0px 8px;
  margin-left: -5px;
}

.comment-like-heart .comment-like-heart-image {
  min-width: 20px;
  text-align: center;
  border: 1px solid #333232;
  border-radius: 50%;
  padding: 0px 3px;
}
.live-event-comment-message .comment-like-heart-image,
.live-event-comment-message .comment-like-heart-count {
  max-height: 20px;
}

.event-comment-like-box .comment-like-heart {
  position: absolute;
  bottom: -10px;
  right: 0;
}
.date-portals {
  color: #858585;
  padding-left: 12px;
  font-size: 15px;
}
.date-portals-title {
  color: white;
  margin-right: 5px;
}

.popup-detail-box {
  height: 100vh;
  overflow: hidden;
}
.detail-page-box {
  height: 90vh;
  overflow: auto;
}
.css-fwfldc-MuiInputBase-root-MuiOutlinedInput-root,
.css-qgdod3 {
  color: white;
}
.feed-video {
  margin: 110px !important;
}
.feed-video-page {
  margin: 126px 20px !important;
}
.notifications-circle {
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
  font-size: 12px;
  margin-right: 5px;
}

.notifications-circle-seen {
  color: rgb(51 56 61 / 8%);
  fill: rgb(51 56 61 / 8%);
}
.notification-title {
  color: var(--portal-theme-primary);
}
.notification-item-box {
  display: flex;
  margin-bottom: 1px;
  padding: 10px;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 0;
}
.notification-item:hover .notifications-delete-button,
.notification-item-unread:hover .notifications-delete-button {
  visibility: visible;
}
.notification-item,
.notification-item:hover {
  background-color: rgba(145, 158, 171, 0.08) !important;
}
.notification-item-unread,
.notification-item-unread:hover {
  background-color: rgba(145, 158, 171, 0.08) !important;
}

.notification-item-unread span {
  color: #fff;
}
.notification-icons-btn {
  background-color: var(--portal-theme-secondary);
  color: var(--portal-theme-primary);
}
.notification-popover-paper {
  top: 5px !important;
}
.notification-popover {
  background-color: var(--sidebars-background-color);
  color: #fff;
}
.notification-popover-loader {
  position: absolute;
  top: 40%;
  left: 45%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #555;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* .image-slider-general {
  margin: 50px 50px 10px 50px !important;
} */
.image-slider-general .image-gallery-icon svg {
  height: 40px;
  width: 40px;
  font-size: 16px;
  color: var(--button-text-color);
  background-color: var(--portal-theme-secondary);
  border-radius: 50%;
}
.image-slider-general .image-thumbnails {
  display: flex;
  justify-content: center;
}

.image-slider-general .main-image img {
  max-height: 400px;
}

.image-slider-general .image-thumbnails img {
  width: 60px;
  height: 50px;
  cursor: pointer;
}
.image-slider-general .image-thumbnails .is_selected {
  border: 1px solid var(--portal-theme-primary);
  padding: 3px;
}


.mui-without-bg-custom-table {
  background-color: #000;
  border: 1px solid #fff;
}

.mui-without-bg-custom-table .MuiPaper-root,
.mui-without-bg-custom-table .Mui-selected,
.mui-without-bg-custom-table .MuiTableRow-hover:hover {
  background-color: #000;
}
.mui-without-bg-custom-table .MuiTablePagination-root p {
  margin-bottom: 0;
}

.mui-without-bg-custom-table .MuiCheckbox-root {
  padding: 0;
}

.mui-without-bg-custom-table .MuiPaper-elevation {
  background-color: transparent;
}

.mui-without-bg-custom-table .MuiOutlinedInput-root {
  /* border: 1px solid #8f8f8f; */
  color: white !important;
}

.mui-without-bg-custom-table .mui-table-footer {
  display: flex;
  justify-content: space-between;
}

.mui-without-bg-custom-table .mui-table-footer .rows_selected_text {
  color: var(--portal-theme-primary);
  padding-left: 20px;
  padding-top: 15px;
  font-size: 1rem;
  font-weight: 400;
}
.pagination-style {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}
.MuiTablePagination-root p ,
.MuiTablePagination-root button
{
  color: #fff !important;
}




.manage-program-chip-success {
  width: 83px;
  text-align: center;
}
.bagde-image {
  display: inherit;
  height: auto;
  width: 25px;
  margin-left: 10px;
}
.card-with-background {
  background-color: var(--sidebars-background-color);
  border: none;
}
.card-with-background .MuiPaper-root,
.card-with-background .Mui-selected,
.card-with-background .MuiTableRow-hover:hover {
  background-color: rgba(145, 158, 171, 0.08);
}
.MuiTableCell-root span{
  color: white !important;
}
.Pagination-select{
  color:white !important;
}
.anchor-style {
  color: #fff;
  color: var(--input-text-color);
  cursor: default;
  text-decoration: none;
  cursor: pointer;
}
.css-1x6apsu{
color:white !important;
}
.table-pagination-box p{
  margin-top: 0 !important;
}
.MuiPagination-ul li button{
  color: white;
}
.css-66p83r.css-66p83r.css-66p83r {
  color: white !important;
}